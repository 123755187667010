// src/components/ContactForm.js
import React, { useState } from 'react';
import './ContactForm.css';
import ContactFormViewModel from '../viewModels/ContactFormViewModel'; // Import ViewModel

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        comment: ''
    });

    const viewModel = ContactFormViewModel(); // Instantiate ViewModel

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const formSubmitted = await viewModel.submitForm(formData);

        if (formSubmitted) {
            // Reset form fields after successful submission
            setFormData({
                name: '',
                email: '',
                comment: ''
            });

            alert('Form submitted successfully!');
        } else {
            alert('Failed to submit form. Please try again later.');
        }
    };

    return (
        <div className="contact-form-container">
            <h2>Contact Us</h2>
            <form onSubmit={handleSubmit} className="contact-form">
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="comment">Comment</label>
                    <textarea
                        id="comment"
                        name="comment"
                        value={formData.comment}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default ContactForm;
