import React, { useEffect } from 'react';
import Header from './components/Header';
import Section from './components/Section';
import HomeSection from './components/HomeSection'; // Import HomeSection
import FeaturesSection from './components/FeaturesSection';
import AboutSection from './components/AboutSection'; 
import ContactForm from './components/ContactForm';
import Footer from './components/Footer'; // Import Footer
import './App.css';

function App() {
    useEffect(() => {
        const sections = document.querySelectorAll("section");
        const navLinks = document.querySelectorAll("nav ul li a");

        const options = {
            threshold: 0.6
        };

        let observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    navLinks.forEach(link => {
                        link.classList.remove("active");
                        if (entry.target.id === link.getAttribute("href").substring(1)) {
                            link.classList.add("active");
                        }
                    });
                }
            });
        }, options);

        sections.forEach(section => {
            observer.observe(section);
        });
    }, []);

    return (
        <div className="App">
            <Header />
            <main>
                <Section id="home">
                    <HomeSection />
                </Section>
                <Section id="features">
                    <FeaturesSection />
                    
                </Section>
                <Section id="about">
                    <AboutSection />
                </Section>
                <Section id="contact">
                    <ContactForm />
                </Section>
            </main>
            <Footer /> {/* Add Footer component */}
        </div>
    );
}

export default App;
