// src/components/Section.js
import React from 'react';
import './Section.css';

const Section = ({ id, children }) => {
    return (
        <section id={id}>
            {children}
        </section>
    );
};

export default Section;
