// src/components/FeaturesSection.js
import React from 'react';
import './FeaturesSection.css';
import { ReactComponent as CocoChatSVG } from '../assets/coco-chat.svg';
import { ReactComponent as LailaSVG } from '../assets/laila.svg';

const FeaturesSection = () => {
    return (
        <div className="features-container">
            <div className="app-item">
                <div className="app-icon">
                    <CocoChatSVG />
                </div>
                <div className="app-details">
                    <h2>Coco Chat</h2>
                    <p>An app where users can create AI Avatars that can converse naturally via text or voice. The avatars are presented as 3D models and are optimized for Apple Vision Pro.</p>
                </div>
            </div>
            <div className="app-item">
                <div className="app-icon">
                    <LailaSVG />
                </div>
                <div className="app-details">
                    <h2>Laila</h2>
                    <p>A social media app powered by AI social media influencers. Users can interact via text and voice. The app monetizes through marketing affiliate programs where AI influencers promote affiliated products.</p>
                </div>
            </div>
        </div>
    );
};

export default FeaturesSection;
