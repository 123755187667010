// src/components/HomeSection.js
import React from 'react';
import './HomeSection.css';
import { ReactComponent as BusinessSVG } from '../assets/business.svg'; // Import SVG

const HomeSection = () => {
    return (
        <div className="home-container">
            <div className="home-left">
                <h1>Make Your Business More Profitable</h1>
                <p>Hiring our IT consulting firm empowers your business with cutting-edge expertise in IoT, AI, 3D generation, and mobile development. We provide tailored solutions that drive innovation and profitability, ensuring you remain competitive. Our deep knowledge of AWS and cloud integration enhances scalability and efficiency, delivering optimal technology investments. With a client-focused approach, we transform challenges into growth opportunities, maximizing your business potential through strategic IT advancements.</p>
            </div>
            <div className="home-right">
                <BusinessSVG />
            </div>
        </div>
    );
};

export default HomeSection;
