// src/components/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <p>&copy; 2024 Stone Tree Labs, LLC. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
