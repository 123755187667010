// src/components/AboutSection.js
import React from 'react';
import './AboutSection.css';

const AboutSection = () => {
    return (
        <div className="about-container">
            <h2>Team</h2>
            <p>
            Our dynamic team at Stone Tree Labs comprises seasoned professionals adept in cutting-edge technologies such as IoT, AI, 3D generation, mobile development, and AWS services. Each member brings a wealth of experience and innovation to the table, collaborating seamlessly to craft transformative solutions tailored to our clients' specific needs. With a passion for pushing the boundaries of technology, we thrive on solving complex challenges and delivering robust, scalable solutions that drive business growth. Our collective expertise not only spans technical domains but also encompasses a deep understanding of industry trends and best practices. Committed to excellence, we continually strive to stay ahead in an ever-evolving technological landscape, ensuring our clients receive the highest standards of service and innovation. At Stone Tree Labs, we are not just a team; we are your dedicated partners in harnessing the power of technology to achieve your business goals.
            </p>
        </div>
    );
};

export default AboutSection;
