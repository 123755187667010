// src/viewModels/ContactFormViewModel.js
const ContactFormViewModel = () => {
    const submitForm = async (formData) => {
        try {
            const response = await fetch('https://67nojpa3vc.execute-api.us-west-2.amazonaws.com/Prod/contacts/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                throw new Error('Failed to submit form');
            }

            return true; // Successful submission
        } catch (error) {
            console.error('Error submitting form:', error);
            return false; // Failed submission
        }
    };

    return {
        submitForm
    };
};

export default ContactFormViewModel;
